import { Login } from "@reporter/openapi";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from 'primereact/inputtext';
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { login } from "../store/account.slice";

export const LoginPage: React.FC = () => {
    const dispatch = useDispatch();
    const [state, setState] = useState<Login>({ userName: "", password: "" });

    return <LoginPageStyles>
        <Card footer={<div className='footer'><Button type="submit" form="login-form" onClick={() => {
            login(state)(dispatch).subscribe();
        }}>Login</Button></div>}>
            <form className="p-fluid">
                <div className="field">
                    <span className="p-float-label">
                        <InputText id="userName" name="userName" value={state.userName} onChange={(e) => setState({ ...state, userName: e.target.value })} />
                        <label htmlFor="userName">UserName *</label>
                    </span>
                </div>
                <div className="field">
                    <span className="p-float-label">
                        <InputText id="password" name="password" value={state.password} type="password" autoComplete="on" onChange={(e) => setState({ ...state, password: e.target.value })} />
                        <label htmlFor="password">Password *</label>
                    </span>
                </div>
                <div><Link to={"/remind-password"}>Forgot password?</Link></div>
            </form>
            
            
        </Card>
    </LoginPageStyles>;
};

export const LoginPageStyles = styled.div`
   .footer {
        display: flex;
        justify-content: center;
        align-items: baseline;
   }
`;