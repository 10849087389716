import { Skeleton } from 'primereact/skeleton';
import styled from 'styled-components';
import { TimeEntryGroup } from "../store/time-entries";

export type TimeEntryHeaderProps = {
    isLoading: boolean;
    group: TimeEntryGroup;
};

export const TimeEntryHeader = ({ isLoading, group }: TimeEntryHeaderProps) => {

    if (isLoading) {
        return <Skeleton />;
    }

    return <TimeEntryHeaderStyles><div>{group.key}</div><div>{group.minutes / 60}h</div></TimeEntryHeaderStyles>;
};

export const TimeEntryHeaderStyles = styled.div`
    
    display: flex;
    justify-content: space-between;
`;

