import { Configuration } from "@reporter/openapi";
import { BaseAPI } from "@reporter/openapi/dist/base";
import globalAxios, { AxiosInstance } from 'axios';
import { from, Observable, of, switchMap } from "rxjs";
import { apiMiddleware } from "./api-middleware";

globalAxios.interceptors.request.use(apiMiddleware.start, apiMiddleware.error);
globalAxios.interceptors.response.use(apiMiddleware.success, apiMiddleware.error);

export class ApiFactory {

    private axios: AxiosInstance

    constructor(axios: AxiosInstance) {
        this.axios = axios;
    }

    create<T extends BaseAPI>(BaseApiImpl: { new(configuration?: Configuration, basePath?: string, axios?: AxiosInstance): T }): Observable<T> {
        return from([1]).pipe(
            switchMap(_ => {
                var basePath = process.env.NODE_ENV === 'development' ? "https://localhost:3000" : "https://oun.me"
                var config = new Configuration({ basePath });
                var api = new BaseApiImpl(config, basePath, this.axios);
                return of(api);
            })
        );
    }
}

export const apiFactory = new ApiFactory(globalAxios);