import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { formatISO } from 'date-fns';

export type NotificationType = {
  id?: number;
  message?: string[];
  timestamp?: string;
  title?: string;
  severity?: 'success' | 'info' | 'warn' | 'error';
  toastDuration?: number;
  toastOnly?: boolean;
};

export interface NotificationState {
  notifications: NotificationType[];
  toast: NotificationType | undefined;
}

const initialState = {
  notifications: [],
  toast: undefined,
} as NotificationState;

let notificationIndex = 1;

export const slice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    sendNotification: (state, action: PayloadAction<NotificationType>) => {
      const notification = {
        ...action.payload,
        id: notificationIndex++,
        timestamp: formatISO(new Date()),
      };
      state.notifications = notification.toastOnly ? [...state.notifications] : [...state.notifications, notification];
      state.toast = notification;
    },
    removeNotification: (state, action: PayloadAction<number>) => {
      const notificationId = action.payload;
      state.notifications = state.notifications.filter((notification: NotificationType) => notification.id !== notificationId);
      state.toast = undefined;
    },
    clearAllNotifications: (state) => {
      state.notifications = [];
      state.toast = undefined;
    },
  },
});

export const { sendNotification, removeNotification, clearAllNotifications } = slice.actions;

export default slice.reducer;
