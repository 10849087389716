import { Role, User, UsersApi } from "@reporter/openapi";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from 'primereact/multiselect';
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { concatMap, from, map, of, switchMap, tap } from "rxjs";
import styled from "styled-components";
import { apiFactory } from "../api-factory";

type UserDetailsState = {
    user: User;
    succeed?: boolean;
};

const initialValue: UserDetailsState = { user: { id: '', email: '', userName: '', roles: [] } };

export const UserDetails = () => {

    const navigate = useNavigate();
    const { userId } = useParams();;
    const [state, setState] = useState<UserDetailsState>(initialValue);

    useEffect(() => {
        if (state.succeed) {
            navigate('/app/users');
        }
    }, [state.succeed, navigate]);

    useEffect(() => {
        const subscription = of(userId).pipe(
            concatMap(id => {
                if (userId !== 'add') {
                    return apiFactory.create(UsersApi).pipe(switchMap(api => api.getUser(id!)), map(response => response.data));
                }
                return of(initialValue.user);
            }),
            tap(user => setState(prev => ({ ...prev, user })))
        ).subscribe();
        return () => subscription.unsubscribe();
    }, [userId]);

    const footer = <div className='footer'>
        {state.user.id && <Button label="Delete" className="p-button-danger p-button-sm" onClick={() => {
            apiFactory.create(UsersApi).pipe(
                switchMap(api => from(api.deleteUser(state.user.id))),
                tap(() => setState(prev => ({ ...prev, succeed: true })))
            ).subscribe();
        }} />}
        <Button label="Cancel" className="p-button-secondary p-button-sm" onClick={() => navigate('/app/users')} />
        {<Button label="Save" type='submit' className="p-button-primary p-button-sm" form='submit-task' onClick={e => {
            if (!state.user.id && state.user.userName && state.user.email) {
                apiFactory.create(UsersApi).pipe(
                    switchMap(api => api.registerUser({ userName: state.user.userName, email: state.user.email, roles: [] })),
                    tap(() => setState(prev => ({ ...prev, succeed: true })))
                ).subscribe();
            }
            if(state.user.id) {
                apiFactory.create(UsersApi).pipe(
                    switchMap(api => api.saveUser(state.user.id, { roles: state.user.roles })),
                    tap(() => setState(prev => ({ ...prev, succeed: true })))
                ).subscribe();
            }
        }} />}
    </div>;

    return <UserDetailsStyles>
        <Card footer={footer}>
            <div className="p-fluid">
                <div className="field">
                    <span className="p-float-label">
                        <InputText disabled={true} id="id" name="id" value={state.user.id} />
                        <label htmlFor="id">Id</label>
                    </span>
                </div>
                <div className="field">
                    <span className="p-float-label">
                        <InputText id="userName" name="userName" value={state.user.userName} onChange={(e) => {
                            setState(prev => ({ ...prev, user: { ...prev.user, userName: e.target.value } }));
                        }} />
                        <label htmlFor="userName">Username *</label>
                    </span>
                </div>
                <div className="field">
                    <span className="p-float-label">
                        <InputText id="email" name="email" value={state.user.email} onChange={(e) => {
                            setState(prev => ({ ...prev, user: { ...prev.user, email: e.target.value } }));
                        }} />
                        <label htmlFor="email">Email *</label>
                    </span>
                </div>
                <div className="field">
                    <span className="p-float-label">
                        <MultiSelect value={state.user.roles} onChange={(e) => setState(prev => ({ ...prev, user: { ...prev.user, roles: e.value } }))} options={Object.values(Role)} maxSelectedLabels={3} />
                        <label htmlFor="roles">Roles</label>
                    </span>
                </div>
            </div>
        </Card>
    </UserDetailsStyles>;
};


const UserDetailsStyles = styled.div`
   .footer {
    display: flex;
    justify-content: center;
    align-items: baseline;
    
    button + button {
        margin-left: 1rem;
    }
   }
`;