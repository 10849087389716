import { Project } from "@reporter/openapi";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export type ProjectListItemProps = {
    project: Project;
};

export const ProjectListItem = ({ project }: ProjectListItemProps) => {

    const navigate = useNavigate();

    return <ProjectListItemStyles>
        <div className="item" onClick={() => navigate(`${project.code}`)}>
            <div>{project.code}</div>
            <p>{project.name}</p>
            <p>{project.customerName}</p>
            <p>{project.location}</p>
        </div>

    </ProjectListItemStyles>;
};


export const ProjectListItemStyles = styled.div`
    
    .item {
       
       padding:1rem;
       margin: 0 -1rem 0 -1rem;

       :hover {
           background-color: var(--surface-c);
       }

       .time {
           display: flex;
           justify-content: space-between;
       }

       .fake-description {
            margin: 16px 0;
       }
   }
`;