import { TimeEntry } from '@reporter/openapi';
import { format, formatDuration, intervalToDuration, parseISO } from 'date-fns';
import { Skeleton } from "primereact/skeleton";
import { useNavigate } from "react-router-dom";
import styled from 'styled-components';

export type TimeEntryListItemProps = {
    isLoading: boolean;
    entry: TimeEntry;
};

export const TimeEntryListItem = ({ isLoading, entry }: TimeEntryListItemProps) => {

    const navigate = useNavigate();

    if (isLoading) {
        return <TimeEntryListItemStyles>
            <div className="item">
                <div><Skeleton /></div>
                <div className='fake-description'><Skeleton height="8rem" /></div>
                <div className='time'><Skeleton /></div>
            </div>
        </TimeEntryListItemStyles>;
    }

    return <TimeEntryListItemStyles>
        <div className="item" onClick={() => navigate(`${entry.id}`)}>
            <div>{entry.projectCode}</div>
            <p>{entry.description}</p>
            <div className='time'>
                <div>{format(parseISO(entry.start), 'HH:mm')} - {format(parseISO(entry.end), 'HH:mm')}</div>
                <div>{formatDuration(intervalToDuration({ start: parseISO(entry.start), end: parseISO(entry.end), }), { format: ['days', 'hours', 'minutes'] })}</div>
            </div>
        </div>
    </TimeEntryListItemStyles>;
};

export const TimeEntryListItemStyles = styled.div`
    
    .item {
       
       padding:1rem;
       margin: 0 -1rem 0 -1rem;

       :hover {
           background-color: var(--surface-c);
       }

       .time {
           display: flex;
           justify-content: space-between;
       }

       .fake-description {
            margin: 16px 0;
       }
   }
`;