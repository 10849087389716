import { User } from "@reporter/openapi";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export type UserListItemProps = {
    user: User;
};

export const UserListItem = ({ user }: UserListItemProps) => {

    const navigate = useNavigate();

    return <UserListItemStyles>
        <div className="item" onClick={() => navigate(`${user.id}`)}>
            <div>{user.id}</div>
            <p>{user.userName}</p>
            <p>{user.email}</p>
        </div>

    </UserListItemStyles>;
};


export const UserListItemStyles = styled.div`
    
    .item {
       
       padding:1rem;
       margin: 0 -1rem 0 -1rem;

       :hover {
           background-color: var(--surface-c);
       }

       .time {
           display: flex;
           justify-content: space-between;
       }

       .fake-description {
            margin: 16px 0;
       }
   }
`;