import { Toast, ToastMessage } from 'primereact/toast';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createBrowserRouter, RouteObject, RouterProvider } from 'react-router-dom';
import styled from 'styled-components';
import GlobalStyles from './GlobalStyles';
import { routes as privateRoutes } from './routes/private-routes';
import { routes as publicRoutes } from './routes/public-routes';
import { AccountState, getAccount } from './store/account.slice';
import { NotificationState } from './store/notification.slice';
import { RootState } from './store/store.config';

function App() {

  const dispatch = useDispatch();
  const { account, loading } = useSelector<RootState, AccountState>(state => state.account);
  const toastRef = useRef<Toast>(null);
  const { toast } = useSelector<RootState, NotificationState>((state) => state.notification);

  useEffect(() => {
    const subscription = getAccount()(dispatch).subscribe();
    return () => subscription.unsubscribe();
  }, [dispatch]);

  useEffect(() => {
    toast?.message?.forEach((message) => {
      const toastMessage: ToastMessage = {
        detail: message,
        life: toast.toastDuration,
        severity: toast.severity,
        summary: toast.title!,
      };
      toastRef.current?.show(toastMessage);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toast]);

  function getRoutes(): Array<RouteObject> {
    if (loading === false) {
      if (!account) {
        return publicRoutes;
      }
      return privateRoutes;
    }
    return [];
  }

  const routes = getRoutes();

  return (
    <>
      <GlobalStyles />
      <AppStyles>
        <Toast ref={toastRef} baseZIndex={100} />
        {routes.length && <RouterProvider router={createBrowserRouter(routes)} />}
      </AppStyles>
    </>
  );
}

export const AppStyles = styled.div`

  /* border: 1px solid red; */
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  .btm-menu {
    display: flex;
    overflow-x: auto;
    position: fixed;
    align-content: space-between;
  }

  .btm-menu::-webkit-scrollbar {
    display: none;
  }

  .btm-menu button {
    margin-right: 15px;
  }
`;

export default App;
