import { Card } from "primereact/card";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { UserListItem } from "../components/UserListItem";
import { RootState } from "../store/store.config";
import { fetchUsers, UsersState } from "../store/users.slice";

export const Users = () => {
    const dispatch = useDispatch();
    const { users } = useSelector<RootState, UsersState>(state => state.users);

    useEffect(() => {
        const subscription = fetchUsers()(dispatch).subscribe();
        return () => subscription.unsubscribe();
    }, [dispatch]);

    return <UsersStyles>{users.map(user => <Card key={user.id} className="items"><UserListItem user={user} /></Card>)}</UsersStyles>;
};


export const UsersStyles = styled.div`
    .items {
        margin: 1rem;
    }

    button + button {
        margin-left: 1rem;
    }
`;
