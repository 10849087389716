import { Role } from '@reporter/openapi';
import { intersection } from 'lodash';
import { Menubar } from 'primereact/menubar';
import { MenuItem } from "primereact/menuitem";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { AccountState, logout } from "../store/account.slice";
import { RootState } from "../store/store.config";

export const Root = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { account } = useSelector<RootState, AccountState>(state => state.account);
    account?.roles?.includes("Admin")
    const items: Partial<MenuItem & { roles?: Role[] }> [] = [
        {
            label: 'Projects',
            icon: 'pi pi-fw pi-file',
            items: [{
                icon: 'pi pi-fw pi-bars',
                label: 'List',
                command: e => navigate('/app/projects')
            }, {
                label: 'New',
                icon: 'pi pi-fw pi-plus',
                command: e => navigate('/app/projects/add'),
            }],
            roles: ["Admin"]
        },
        {
            label: 'Users',
            icon: 'pi pi-fw pi-user',
            items: [{
                icon: 'pi pi-fw pi-bars',
                label: 'List',
                command: e => navigate('/app/users')
            }, {
                label: 'New',
                icon: 'pi pi-fw pi-plus',
                command: e => navigate('/app/users/add'),
            }],
            roles: ["Admin"]
        },
        {
            label: 'Entries',
            icon: 'pi pi-fw pi-calendar',
            items: [{
                icon: 'pi pi-fw pi-bars',
                label: 'List',
                command: e => navigate('/app/entries'),
            }, {
                label: 'New',
                icon: 'pi pi-fw pi-plus',
                command: e => navigate('/app/entries/add'),
            }]
        },
        {
            label: 'Quit',
            icon: 'pi pi-fw pi-power-off',
            command: e => logout()(dispatch).subscribe()
        }
    ];

    return <RootStyles>
        <div className='layout-topbar'>
            <Menubar model={items.filter(item => !item.roles || intersection(account?.roles, item.roles).length > 0)} end={<div className='menu-end'>{account?.userName}</div>} />
        </div>
        <div className='topbar-fake'></div>
        <Outlet />
    </RootStyles>
};

export const RootStyles = styled.div`
    .layout-topbar {
        width: 100%;
        position: fixed;
        z-index: 99;
    }

    .topbar-fake {
        height: 58px;
    }

    .menu-end {
        padding: 0 20px;
    }
`;
