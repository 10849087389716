import { TimeEntry } from "@reporter/openapi";

export type TimeEntryGroup = {
    key: string;
    entries: Array<TimeEntry>;
    minutes: number;
};

export const mockedGroups: Array<TimeEntryGroup> = [
    {
        key: '2022-12-01', entries: [
            { id: 1, projectCode: "177", start: '2022-12-01T09:00+02:00', end: '2022-12-01T12:00+02:00', description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro temporibus repellendus delectus aut similique provident earum nam eius ad? Excepturi, illo! Commodi illo enim in distinctio culpa repellat corporis quia.' },
            { id: 2, projectCode: "177", start: '2022-12-01T12:00+02:00', end: '2022-12-01T12:45+02:00', description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro temporibus repellendus delectus aut similique provident earum nam eius ad? Excepturi, illo! Commodi illo enim in distinctio culpa repellat corporis quia.' },
            { id: 3, projectCode: "177", start: '2022-12-01T09:00+02:00', end: '2022-12-01T12:00+02:00', description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro temporibus repellendus delectus aut similique provident earum nam eius ad? Excepturi, illo! Commodi illo enim in distinctio culpa repellat corporis quia.' },
            { id: 4, projectCode: "177", start: '2022-12-01T09:00+02:00', end: '2022-12-01T12:00+02:00', description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro temporibus repellendus delectus aut similique provident earum nam eius ad? Excepturi, illo! Commodi illo enim in distinctio culpa repellat corporis quia.' },
            { id: 5, projectCode: "177", start: '2022-12-01T09:00+02:00', end: '2022-12-01T12:00+02:00', description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro temporibus repellendus delectus aut similique provident earum nam eius ad? Excepturi, illo! Commodi illo enim in distinctio culpa repellat corporis quia.' },
            { id: 6, projectCode: "177", start: '2022-12-01T09:00+02:00', end: '2022-12-01T12:00+02:00', description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro temporibus repellendus delectus aut similique provident earum nam eius ad? Excepturi, illo! Commodi illo enim in distinctio culpa repellat corporis quia.' },
        ], minutes: 0
    },
    {
        key: '2022-12-02', entries: [
            { id: 7, projectCode: "177", start: '2022-12-02T09:00+02:00', end: '2022-12-02T12:00+02:00', description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro temporibus repellendus delectus aut similique provident earum nam eius ad? Excepturi, illo! Commodi illo enim in distinctio culpa repellat corporis quia.' },
            { id: 8, projectCode: "255", start: '2022-12-02T12:00+02:00', end: '2022-12-02T12:45+02:00', description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro temporibus repellendus delectus aut similique provident earum nam eius ad? Excepturi, illo! Commodi illo enim in distinctio culpa repellat corporis quia.' },
            { id: 9, projectCode: "200", start: '2022-12-02T12:00+02:00', end: '2022-12-02T12:45+02:00', description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro temporibus repellendus delectus aut similique provident earum nam eius ad? Excepturi, illo! Commodi illo enim in distinctio culpa repellat corporis quia.' },
        ], minutes: 0
    }
];