import { Project, ProjectsApi } from "@reporter/openapi";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { concatMap, map, of, switchMap, tap } from "rxjs";
import styled from "styled-components";
import { apiFactory } from "../api-factory";

type ProjectDetailsState = {
    project: Project;
    succeed?: boolean;
};

const initialValue: ProjectDetailsState = { project: { code: '', name: '', customerName: '', location: '' } };

export const ProjectDetails = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const code = params.code;
    const [state, setState] = useState<ProjectDetailsState>(initialValue);

    const footer = <div className='footer'>
        <Button label="Cancel" className="p-button-secondary p-button-sm" onClick={() => navigate('/app/projects')} />
        <Button label="Save" type='submit' className="p-button-primary p-button-sm" form='submit-task' onClick={e => {
            if (state.project?.code && state.project.name && state.project.customerName && state.project.location) {
                apiFactory.create(ProjectsApi).pipe(
                    switchMap(api => api.saveProject(state.project)),
                    tap(() => setState(prev => ({ ...prev, succeed: true })))
                ).subscribe();
            }
        }} />
    </div>;

    useEffect(() => {
        const subscription = of(code).pipe(
            concatMap(code => {
                if (code && code !== 'add') {
                    return apiFactory.create(ProjectsApi).pipe(switchMap(api => api.getProject(code)), map(response => response.data));
                }
                return of(initialValue.project);
            }),
            tap(project => setState(prev => ({ ...prev, project })))
        ).subscribe();
        return () => subscription.unsubscribe();
    }, [dispatch, code]);

    useEffect(() => {
        if (state.succeed) {
            navigate('/app/projects');
        }
    }, [state.succeed, navigate]);

    return <ProjectDetailsStyles>
        <Card footer={footer}>
            <div className="p-fluid">
                <div className="field">
                    <span className="p-float-label">
                        <InputText disabled={(Boolean(code) && code !== 'add')} id="code" name="code" value={state.project?.code} onChange={(e) => {
                            setState(prev => ({ ...prev, project: { ...prev.project, code: e.target.value } }));
                        }} />
                        <label htmlFor="code">Code *</label>
                    </span>
                </div>
                <div className="field">
                    <span className="p-float-label">
                        <InputTextarea id="name" name="name" value={state.project?.name} rows={3} onChange={(e) => {
                            setState(prev => ({ ...prev, project: { ...prev.project, name: e.target.value } }));
                        }} />
                        <label htmlFor="name">Name *</label>
                    </span>
                </div>
                <div className="field">
                    <span className="p-float-label">
                        <InputText id="customerName" name="customerName" value={state.project?.customerName} onChange={(e) => {
                            setState(prev => ({ ...prev, project: { ...prev.project, customerName: e.target.value } }));
                        }} />
                        <label htmlFor="customerName">Customer name *</label>
                    </span>
                </div>
                <div className="field">
                    <span className="p-float-label">
                        <InputText id="location" name="location" value={state.project?.location} onChange={(e) => {
                            setState(prev => ({ ...prev, project: { ...prev.project, location: e.target.value } }));
                        }} />
                        <label htmlFor="location">Location *</label>
                    </span>
                </div>
            </div>
        </Card>
    </ProjectDetailsStyles>;
};

const ProjectDetailsStyles = styled.div`
   .footer {
    display: flex;
    justify-content: center;
    align-items: baseline;
    
    button + button {
        margin-left: 1rem;
    }
   }
`;