import { Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { sendNotification } from './store/notification.slice';
import { finishRequest, startRequest } from './store/request.slice';
import { logoutSuccess } from './store/account.slice';
import { AxiosResponse } from 'axios';

let dispatcher = (action: any): Dispatch => action;
export const globalDispatch = (action: any) => dispatcher(action);
export const ApiMiddleware: Middleware = (api: MiddlewareAPI) => {
  dispatcher = api.dispatch;
  return (next) => (action) => next(action);
};

export const apiMiddleware = {
  start: (request: any) => {
    globalDispatch(startRequest());
    return Promise.resolve(request);
  },
  success: (response: AxiosResponse<any>) => {
    globalDispatch(finishRequest());
    if(!response.config.params?.supressMessage && response.config.method === "post") {
      globalDispatch(sendNotification({
        severity: "success",
        message: ["Data saved"],
        title: "success"
      }));
    }
    return Promise.resolve(response);
  },
  error: (error: any) => {
    globalDispatch(finishRequest());
    if(error?.response?.status === 401) {
      globalDispatch(logoutSuccess());
    } else {
      globalDispatch(sendNotification({
        severity: "error",
        message: ["error"],
        title: "error"
      }));
    }
    return Promise.reject(error);
  }
};