import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProjectsApi, Project } from "@reporter/openapi";
import { catchError, from, of, switchMap, tap } from "rxjs";
import { apiFactory } from "../api-factory";

export interface ProjectsState {
    projects: Array<Project>;
    isLoading: boolean;
}

const initialState: ProjectsState = { projects: [], isLoading: false };

export const slice = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        startLoading: state => {
            state.isLoading = true;
        },
        projectsSuccess: (state: ProjectsState, action: PayloadAction<Array<Project>>) => {
            state.projects = action.payload;
            state.isLoading = false;
        },
        hasError: (state: ProjectsState, action) => {
            state.isLoading = false;
        }
    },
});

export const fetchProjects = () => (dispatch: any) => {
    return from([0])
        .pipe(
            switchMap(_ => of(dispatch(slice.actions.startLoading()))),
            switchMap(() => apiFactory.create(ProjectsApi)),
            switchMap(api => from(api.getProjects())),
            tap(response => dispatch(slice.actions.projectsSuccess(response.data))),
            catchError(err => dispatch(slice.actions.hasError({ message: err.message }))),
        );
}