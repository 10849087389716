import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/lara-dark-indigo/theme.css';
import "react-datepicker/dist/react-datepicker.css";
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
    html, body, #root, #root>div {
        height: 100%;
        margin: 0;
    }
`;

export default GlobalStyles;