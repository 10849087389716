import { TimeEntriesApi, TimeEntry } from '@reporter/openapi';
import { formatISO, parseISO } from 'date-fns';
import { ru } from 'date-fns/locale';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { useEffect, useState } from 'react';
import DatetimePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { concatMap, from, map, of, switchMap, tap } from 'rxjs';
import styled from 'styled-components';
import { apiFactory } from '../api-factory';
import { fetchProjects, ProjectsState } from '../store/projects.slice';
import { RootState } from '../store/store.config';


type TimeEntryDetailsState = {
    entry: Omit<TimeEntry, 'end' | 'start' | 'description'> & { start: Date | null, end: Date | null, description: string };
    succeed?: boolean;
};

const initialValue: TimeEntryDetailsState = { entry: { description: '', projectCode: '', start: null, end: null } };

export const TimeEntryDetails = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const entryId = Number(params.entryId);
    const { projects } = useSelector<RootState, ProjectsState>(state => state.projects);
    const [state, setState] = useState<TimeEntryDetailsState>(initialValue);

    const filterEndDates = (date: Date) => {
        return true;
    };
    const filterEndTimes = (time: Date) => {
        return true;
    };

    useEffect(() => {
        if(state.succeed) {
            navigate('/app/entries');
        }
    }, [state.succeed, navigate]);

    useEffect(() => {
        const subscription = fetchProjects()(dispatch).subscribe();
        return () => subscription.unsubscribe();
    }, [dispatch]);

    useEffect(() => {
        const subscription = of(entryId).pipe(
            concatMap(id => {
                if(isNaN(id)) {
                    return of(initialValue.entry);
                }
                return apiFactory.create(TimeEntriesApi).pipe(
                    switchMap(api => from(api.getTimeEntry(entryId))),
                    map(respose => respose.data),
                    map(te => ({ 
                        id: te.id,
                        projectCode: te.projectCode,
                        start: parseISO(te.start),
                        end: parseISO(te.end),
                        description: te.description || ''
                    }))
                );
            }),
            tap(entry => setState(prev => ({ ...prev, entry })))
        )
        .subscribe();
        return () => subscription.unsubscribe();
    }, [entryId]);

    const footer = <div className='footer'>
        {!isNaN(entryId) && <Button label="Delete" className="p-button-danger p-button-sm" onClick={() => {
            apiFactory.create(TimeEntriesApi).pipe(
                    switchMap(api => from(api.deleteTimeEntry(entryId))),
                    tap(() => setState(prev => ({ ...prev, succeed: true })))
                ).subscribe();
        }} />}
        <Button label="Cancel" className="p-button-secondary p-button-sm" onClick={() => navigate('/app/entries')} />
        <Button label="Save" type='submit' className="p-button-primary p-button-sm" form='submit-task' onClick={e => {
            if (state.entry.start && state.entry.end && state.entry.projectCode) {
                const start = formatISO(state.entry.start);
                const end = formatISO(state.entry.end);
                apiFactory.create(TimeEntriesApi)
                    .pipe(
                        switchMap(api => from(api.saveTimeEntry({
                            id: entryId,
                            projectCode: state.entry.projectCode,
                            start,
                            end,
                            description: state.entry.description
                        }))),
                        tap(() => setState(prev => ({ ...prev, succeed: true })))
                    ).subscribe();
            }
        }} />
    </div>;

    return <TimeEntryDetailsStyles><Card footer={footer}>
        <div className="p-fluid">
            <div className="field">
                <span className="p-float-label">
                    <Dropdown id="project" name="project" value={state.entry.projectCode} autoFocus onChange={(e) => {
                        setState(prev => ({ ...prev, entry: { ...prev.entry, projectCode: e.value } }));
                    }} options={projects} dataKey="code" optionValue='code' optionLabel='code' />
                    <label htmlFor="project">Project *</label>
                </span>
            </div>
            <div className="field">
                <span className="p-float-label">
                    <DatetimePicker onChange={(date) => {
                        setState(prev => ({ ...prev,  entry: { ...prev.entry, start: date } }));
                    }} autoComplete="off" selected={state.entry.start} showTimeSelect timeIntervals={30} locale={ru} dateFormat="Pp" timeFormat="p" id='start' name='start' className='p-inputtext p-component' />
                    {/* <label htmlFor="start">Start *</label> */}
                </span>
            </div>
            <div className="field">
                <span className="p-float-label">
                    <DatetimePicker onChange={(date) => {
                        setState(prev => ({ ...prev, entry: { ...prev.entry, end: date } }));
                    }} autoComplete="off" selected={state.entry.end} showTimeSelect timeIntervals={30} filterDate={filterEndDates} filterTime={filterEndTimes} locale={ru} dateFormat="Pp" timeFormat="p" id="end" name='end' className='p-inputtext p-component' />
                    {/* <label htmlFor="end">End *</label> */}
                </span>
            </div>
            <div className="field">
                <span className="p-float-label">
                    <InputTextarea id="description" name="description" value={state.entry.description} rows={10} onChange={(e) => {
                        setState(prev => ({ ...prev, entry: { ...prev.entry, description: e.target.value } }));
                    }} />
                    <label htmlFor="description">Description *</label>
                </span>
            </div>
        </div>
    </Card></TimeEntryDetailsStyles>;
};

const TimeEntryDetailsStyles = styled.div`
   .footer {
    display: flex;
    justify-content: center;
    align-items: baseline;
    
    button + button {
        margin-left: 1rem;
    }
   }
`;