import { createSlice } from '@reduxjs/toolkit';

export interface RequestState {
  isActive: boolean;
  count: number;
}

export const initialState = {
  isActive: false,
  count: 0,
} as RequestState;

export const slice = createSlice({
  name: 'request',
  initialState,
  reducers: {
    startRequest: (state) => {
      state.isActive = true;
      state.count++;
    },
    finishRequest: (state) => {
      state.count--;
      state.isActive = Boolean(state.count !== 0);
    },
  },
});

export const { startRequest, finishRequest } = slice.actions;

export default slice.reducer;
