import { Navigate, RouteObject } from "react-router-dom";
import { NoMatch } from "../pages/NoMatch";
import { ProjectDetails } from "../pages/ProjectDetails";
import { Projects } from "../pages/Projects";
import { ProjectsRoot } from "../pages/ProjectsRoot";
import { ResetPasswordPage } from "../pages/ResetPasswordPage";
import { Root } from "../pages/Root";
import { TimeEntries } from "../pages/TimeEntries";
import { TimeEntriesRoot } from "../pages/TimeEntriesRoot";
import { TimeEntryDetails } from "../pages/TimeEntryDetails";
import { UserDetails } from "../pages/UserDetails";
import { Users } from "../pages/Users";
import { UsersRoot } from "../pages/UsersRoot";

export const routes: Array<RouteObject> = [{
  path: 'app',
  element: <Root />,
  children: [
    {
      path: 'entries',
      element: <TimeEntriesRoot />,
      errorElement: <>error</>,
      children: [
        {
          index: true,
          element: <TimeEntries />,
        },
        {
          path: ':entryId',
          element: <TimeEntryDetails />,
        }
      ]
    },
    {
      path: 'users',
      element: <UsersRoot />,
      errorElement: <>error</>,
      children: [
        {
          index: true,
          element: <Users />,
        },
        {
          path: ':userId',
          element: <UserDetails />,
        }
      ]
    },
    {
      path: 'projects',
      element: <ProjectsRoot />,
      errorElement: <>error</>,
      children: [
        {
          index: true,
          element: <Projects />,
        },
        {
          path: ':code',
          element: <ProjectDetails />
        }
      ]
    },
  ]
},
{
  index: true,
  path: '',
  element: <Navigate to="/app/entries" replace={true} />
},
{
  path: '/login',
  element: <Navigate to="/app/entries" replace={true} />
},
{
  path: 'reset-password',
  element: <ResetPasswordPage />
},
{
  path: "*",
  element: <NoMatch />
}
];