import { Navigate, RouteObject } from "react-router-dom";
import { LoginPage } from "../pages/LoginPage";
import { RemindPasswordPage } from "../pages/RemindPasswordPage";
import { ResetPasswordPage } from "../pages/ResetPasswordPage";

export const routes: Array<RouteObject> = [
    {
        path: 'login',
        element: <LoginPage />
    },
    {
        path: 'remind-password',
        element: <RemindPasswordPage />
    },
    {
        path: 'reset-password',
        element: <ResetPasswordPage />
    },
    {
        path: "*",
        element: <Navigate to="/login" replace={true} />
    }
];