import { Card } from "primereact/card";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { ProjectListItem } from "../components/ProjectListItem";
import { fetchProjects, ProjectsState } from "../store/projects.slice";
import { RootState } from "../store/store.config";

export const Projects = () => {
    const dispatch = useDispatch();
    const { projects } = useSelector<RootState, ProjectsState>(state => state.projects)

    useEffect(() => {
        const subscription = fetchProjects()(dispatch).subscribe();
        return () => subscription.unsubscribe();
    }, [dispatch]);

    return <ProjectsStyles>{projects.map(project => <Card key={project.code} className="items"><ProjectListItem project={project} /></Card>)}</ProjectsStyles>;
};

export const ProjectsStyles = styled.div`
    .items {
        margin: 1rem;
    }

    button + button {
        margin-left: 1rem;
    }
`;
