import { ResetPassword, UsersApi } from "@reporter/openapi";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from 'primereact/inputtext';
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { switchMap, tap } from "rxjs";
import styled from "styled-components";
import { apiFactory } from "../api-factory";

export const ResetPasswordPage: React.FC = () => {
    const [urlSearchParams] = useSearchParams();
    var token = urlSearchParams.get('token') || '';
    const [state, setState] = useState<ResetPassword>({ email: '', password: '', token: token });

    return <ResetPasswordPageStyles>
        <Card footer={<div className='footer'><Button type="submit" form="login-form" onClick={() => {
            apiFactory.create(UsersApi).pipe(switchMap(api => api.resetPassword(state)), tap(() => {
                window.location.pathname = '';
            })).subscribe();
        }}>Reset password</Button></div>}>
            <form className="p-fluid">
                <div className="field">
                    <span className="p-float-label">
                        <InputText id="email" name="email" value={state.email} onChange={(e) => setState({ ...state, email: e.target.value })} />
                        <label htmlFor="email">Email *</label>
                    </span>
                </div>
                <div className="field">
                    <span className="p-float-label">
                        <InputText id="password" name="password" value={state.password} type="password" autoComplete="on" onChange={(e) => setState({ ...state, password: e.target.value })} />
                        <label htmlFor="password">Password *</label>
                    </span>
                </div>
            </form>
        </Card>
    </ResetPasswordPageStyles>;
};

export const ResetPasswordPageStyles = styled.div`
   .footer {
        display: flex;
        justify-content: center;
        align-items: baseline;
   }
`;