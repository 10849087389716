import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User, UsersApi } from "@reporter/openapi";
import { catchError, from, of, switchMap, tap } from "rxjs";
import { apiFactory } from "../api-factory";

export type UsersState = {
    users: Array<User>;
    isLoading: boolean;
    error: any;
}

const initialState: UsersState = {
    users: [],
    isLoading: false,
    error: false,
};

export const slice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        startLoading: state => {
            state.isLoading = true;
        },
        usersSuccess: (state: UsersState, action: PayloadAction<Array<User>>) => {
            state.users = action.payload;
            state.isLoading = false;
        },
        hasError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        }
    },
});

const { usersSuccess, hasError } = slice.actions;

export const fetchUsers = () => (dispatch: any) => {
    return from([0])
        .pipe(
            switchMap(_ => of(dispatch(slice.actions.startLoading()))),
            switchMap(() => apiFactory.create(UsersApi)),
            switchMap(api => from(api.getUsers())),
            tap(response => dispatch(usersSuccess(response.data))),
            catchError(err => dispatch(hasError({ message: err.message }))),
        );
}

export default slice.reducer