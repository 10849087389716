import { TimeEntry } from '@reporter/openapi';
import { differenceInMinutes, format, parseISO } from 'date-fns';
import { ru } from 'date-fns/locale';
import { groupBy, mapValues } from 'lodash';
import { Card } from 'primereact/card';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { TimeEntryHeader } from '../components/TimeEntryHeader';
import { TimeEntryListItem } from '../components/TimeEntryListItem';
import { RootState } from '../store/store.config';
import { mockedGroups, TimeEntryGroup } from '../store/time-entries';
import { fetchTimeEntries, TimeEntriesState } from '../store/time-entries.slice';

export const TimeEntries = () => {
    const dispatch = useDispatch();

    const { entries, isLoading } = useSelector<RootState, TimeEntriesState>(state => state.timeEntries);

    useEffect(() => {
        const subscription = fetchTimeEntries()(dispatch).subscribe();
        return () => subscription.unsubscribe();
    }, [dispatch]);

    function groupEntriesByDate(): Array<TimeEntryGroup> {
        const groups = mapValues(
            groupBy(
                entries, timeEntry => format(parseISO(timeEntry.start), 'P', { locale: ru })
            ),
            (timeEntries, key) => ({ key, entries: timeEntries, minutes: getMinutes(timeEntries) } as TimeEntryGroup)
        );
        return Object.values(groups);
    }

    function getMinutes(timeEntries: Array<TimeEntry>) {
        return timeEntries.reduce((acc, entry) => acc += Math.abs(differenceInMinutes(parseISO(entry.end), parseISO(entry.start))), 0);
    }

    return <ItemsStyles>
        {(isLoading ? mockedGroups : groupEntriesByDate()).map((group, index) => <Card key={index} title={<TimeEntryHeader group={group} isLoading={isLoading} />} className="items">
            {group.entries.map((entry: TimeEntry) => <TimeEntryListItem key={entry.id} entry={entry} isLoading={isLoading} />)}
        </Card>)}
    </ItemsStyles>;
};

export const ItemsStyles = styled.div`
    .items {
        margin: 1rem;
    }

    button + button {
        margin-left: 1rem;
    }
`;
