import { RemindPassword, UsersApi } from "@reporter/openapi";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from 'primereact/inputtext';
import { useState } from "react";
import { switchMap, tap } from "rxjs";
import styled from "styled-components";
import { apiFactory } from "../api-factory";

export const RemindPasswordPage: React.FC = () =>  {
    const [state, setState] = useState<RemindPassword>({ email: '' });

    return <RemindPasswordPageStyles>
        <Card footer={<div className='footer'><Button type="submit" form="login-form" onClick={() => {
            apiFactory.create(UsersApi).pipe(switchMap(api => api.remindPassword(state)), tap(() => {
                window.location.pathname = '';
            })).subscribe();
        }}>Remind password</Button></div>}>
            <p>Password change link will send tou your email</p>
            <form className="p-fluid">
                <div className="field">
                    <span className="p-float-label">
                        <InputText id="email" name="email" value={state.email} onChange={(e) => setState({ ...state, email: e.target.value })} />
                        <label htmlFor="email">Email *</label>
                    </span>
                </div>
            </form>
        </Card>
    </RemindPasswordPageStyles>;
};

export const RemindPasswordPageStyles = styled.div`
   .footer {
        display: flex;
        justify-content: center;
        align-items: baseline;
   }
`;