import { useLocation } from "react-router-dom";

export const NoMatch = () => {

    const location = useLocation();

    return <div className="flex justify-content-center">
        <h3>
            No match for <code>{location.pathname}</code>
        </h3>
    </div>
};