import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccountApi, AccountInfo, Login } from "@reporter/openapi";
import { catchError, from, switchMap, tap } from "rxjs";
import { apiFactory } from "../api-factory";

export interface AccountState {
    account?: AccountInfo;
    loading?: boolean;
}

export const initialState = {
} as AccountState;

export const slice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        loginSuccess: (state: AccountState, action: PayloadAction<AccountInfo>) => {
            state.account = action.payload;
        },
        account: (state: AccountState) => {
            state.loading = true;
        },
        accountFailed: (state: AccountState) => {
            state.loading = false;
            state.account = undefined;
        },
        accountSuccess:  (state: AccountState, action: PayloadAction<AccountInfo>) => {
            state.loading = false;
            state.account = action.payload;
        },
        logoutSuccess: (state: AccountState) => {
            state.account = undefined;
        }
    },
});

export const { logoutSuccess } = slice.actions;

export const login = (login: Login) => (dispatch: any) => {
    return from([0])
        .pipe(
            switchMap(() => apiFactory.create(AccountApi)),
            switchMap(api => from(api.login(login, { params: { supressMessage: true } })).pipe(switchMap(() => from(api.getAccountInfo())))),
            tap(response => dispatch(slice.actions.loginSuccess(response.data))),
        );
}

export const logout = () => (dispatch: any) => {
    return from([0])
        .pipe(
            switchMap(() => apiFactory.create(AccountApi)),
            switchMap(api => from(api.logout({ params: { supressMessage: true } }))),
            tap(() => dispatch(logoutSuccess())),
        );
}

export const getAccount = () => (dispatch: any) => {
    return from([0])
        .pipe(
            tap(() => dispatch(slice.actions.account())),
            switchMap(() => apiFactory.create(AccountApi)),
            switchMap(api => from(api.getAccountInfo())),
            tap(response => dispatch(slice.actions.accountSuccess(response.data))),
            catchError(_ => dispatch(slice.actions.accountFailed())),
        );
}

export default slice.reducer;