import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TimeEntriesApi, TimeEntry } from "@reporter/openapi";
import { catchError, from, of, switchMap, tap } from "rxjs";
import { apiFactory } from "../api-factory";

export type TimeEntriesState = {
    entries: Array<TimeEntry>;
    isLoading: boolean;
    error: any;
}

const initialState: TimeEntriesState = {
    entries: [],
    isLoading: false,
    error: false,
};

export const slice = createSlice({
    name: 'time-entries',
    initialState,
    reducers: {
        startLoading: state => {
            state.isLoading = true;
        },
        timeEntriesSuccess: (state: TimeEntriesState, action: PayloadAction<Array<TimeEntry>>) => {
            state.entries = action.payload;
            state.isLoading = false;
        },
        hasError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        }
    },
});

const { timeEntriesSuccess, hasError } = slice.actions;

export const fetchTimeEntries = () => (dispatch: any) => {
    return from([0])
        .pipe(
            switchMap(_ => of(dispatch(slice.actions.startLoading()))),
            switchMap(() => apiFactory.create(TimeEntriesApi)),
            switchMap(api => from(api.getTimeEntries())),
            tap(response => dispatch(timeEntriesSuccess(response.data))),
            catchError(err => dispatch(hasError({ message: err.message }))),
        );
}

export default slice.reducer