import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { ApiMiddleware } from '../api-middleware';
import { slice as timeEntries } from './time-entries.slice';
import { slice as request } from './request.slice';
import { slice as account } from './account.slice';
import { slice as notification } from './notification.slice';
import { slice as projects } from './projects.slice';
import { slice as users } from './users.slice';

const reducer = combineReducers({
  timeEntries: timeEntries.reducer,
  request: request.reducer,
  account: account.reducer,
  notification: notification.reducer,
  projects: projects.reducer,
  users: users.reducer,
})

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([ApiMiddleware])
})

export type RootState = ReturnType<typeof store.getState>;

export default store;